<template>
  <v-container fluid key="test"> 
    <v-card
      v-if="$store.getters.getPipeType === 'BHA'"
      dark
      color="transparent"
      width="99%"
      class="mx-auto mt-10"
      elevation="0">
      <data_table
            :displayname="'Inventory BHA'"
            :options="false"
            :loader="get_data_load"
            :headers="headers_BHA"
            :search_text="'Search (Serial Number, Delivery Ticket, Order Number etc..)'"
            :items="ODP_data"
            :pdf_params="[]"
            :defaultSort="'Date'"
            :type="$store.getters.getPipeType"
            style="margin-left: 0px !important; margin-right: 0px !important;"
    />
    </v-card>
    
    <v-dialog v-model="saveSettingsDialog" max-width="25%" persistent>
      <v-card dark>
      <v-card-text class="pt-5 text-left">
        You have unsaved changes in the table headers. Are you sure you want to leave without saving?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="error" plain @click="this.nextRoute">don't save</v-btn>
        <v-btn text color="primary" plain @click="saveHeaders" :loading="get_data_load">Save settings</v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
    <pipe_dialog
      :dialog="dialog"
      :Itemnr="Itemnr"
      :search="search2"
      :loader="get_data_load"
      :headers="headers2"
      :items="numbers"
      :showRack="showRack"
      :clearList="clearList"
      :location="location"
      :serialFunction="showShippedDT"
      :serialFunction2="showShippedSerials"
    />
    <cert_menu v-model="certdialog" :item="links"> </cert_menu>
    <pipe_dialog
      :dialog="dialog3"
      :Itemnr="Itemnr"
      :search="search2"
      :loader="get_data_load"
      :headers="headers3"
      :items="numbers"
      :showRack="showRack"
      :clearList="clearList"
      :location="location"
      :currentItem="currentItem"
    />
    <v-dialog
      v-model="dialog2"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card dark color="#1C355E" class="justify-center align-center">
        <v-toolbar dense color="transparent" elevation="0">
          <v-btn icon @click="clearRack">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ Serialnr }}</v-toolbar-title>
        </v-toolbar>
        <v-row class="justify-center align-center">
          <v-col cols="4">
            <div class="header-container">
              <div class="header-subtitle text-body-1">Site</div>
              <div class="header-title text-h4">{{ rackinfo.Site }}</div>
              <!-- <v-img
                :src="header_C1"
                :height="header_min_height"
                :width="header_width"
                class="header-img"
                position="center"
              /> -->
            </div>
          </v-col>
          <v-col cols="4">
            <div class="header-container">
              <div class="header-subtitle text-body-1">Rack</div>
              <div class="header-title text-h4">{{ rackinfo.Rack_ID }}</div>
              <!-- <v-img
                :src="header_C2"
                :height="header_min_height"
                :width="header_width"
                class="header-img"
                position="bottom"
              /> -->
            </div>
          </v-col>
          <v-col cols="4">
            <div class="header-container">
              <div class="header-subtitle text-body-1">Item Number</div>
              <v-menu
                open-on-hover
                offset-y
                left
                :close-on-content-click="false"
                v-if="multiple_itemno.istrue"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="header-title text-h4">
                    <v-icon style="padding-right: 10px"
                      >mdi-chevron-down</v-icon
                    >
                    Total Items: {{ multiple_itemno.number }}
                  </div>
                </template>
                <v-list color="transparent" dark class="mt-3">
                  <v-list-item
                    v-for="(key, value) in colormap"
                    :key="`card-${value}`"
                    class="text-h5"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" medium left> info </v-icon>
                      </template>
                      <div
                        class="tooltippen"
                        v-for="(key1, value1) in key.iteminfo"
                        :key="`card-${value1}`"
                      >
                        <b>{{ value1 }}:</b> {{ key1 }}
                      </div>
                    </v-tooltip>
                    {{ value }}
                    <v-icon
                      :style="{ color: computeColor(value) }"
                      style="padding-left: 5px"
                      @mouseover="addBorder(value)"
                      @mouseleave="removeBorder"
                    >
                      brightness_1
                    </v-icon>
                  </v-list-item>
                </v-list>
              </v-menu>
              <div
                v-else
                v-for="(key, value) in colormap"
                :key="`card-${value}`"
                class="header-title text-h4"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon dark v-on="on" medium left> info </v-icon>
                  </template>
                  <br />
                  <div
                    class="tooltippen"
                    v-for="(key1, value1) in key.iteminfo"
                    :key="`card-${value1}`"
                  >
                    <b>{{ value1 }}:</b> {{ key1 }}
                  </div>
                </v-tooltip>
                {{ value }}
                <v-icon
                  :style="{ color: computeColor(value) }"
                  style="padding-left: 5px"
                >
                  brightness_1
                </v-icon>
              </div>
              <!-- <v-img
                :src="header_C3"
                :height="header_min_height"
                :width="header_width"
                class="header-img"
                position="bottom"
              /> -->
            </div>
          </v-col>
        </v-row>
        <v-row class="align-center fill-height" style="height: 100vh">
          <table>
            <tr
              v-for="list in divideRows"
              :key="`card-${list[0].Serial_Number}`"
            >
              <td
                ref="box"
                class="tdclass"
                v-for="pipe in list"
                :key="`card-${pipe.Serial_Number}`"
                :bgcolor="computeColor(pipe.Item_No)"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      ref="green_btn"
                      v-if="getDateDiff(pipe) < 180"
                      v-bind="attrs"
                      v-on="on"
                      color="green"
                      fab
                      small
                    >
                      <span v-if="pipe.Serial_Number === Serialnr"
                        ><b>{{ pipe.Position }}</b></span
                      >
                      <span v-else class="black--text">{{
                        pipe.Position
                      }}</span>
                    </v-btn>
                    <v-btn
                      ref="yellow_btn"
                      v-else-if="
                        (getDateDiff(pipe) < 365) & (getDateDiff(pipe) >= 181)
                      "
                      v-bind="attrs"
                      v-on="on"
                      color="yellow"
                      fab
                      small
                    >
                      <span
                        v-if="pipe.Serial_Number === Serialnr"
                        class="white--text"
                        ><b>{{ pipe.Position }}</b></span
                      >
                      <span v-else class="black--text">{{
                        pipe.Position
                      }}</span>
                    </v-btn>
                    <v-btn
                      ref="red_btnn"
                      v-else
                      v-on="on"
                      color="red"
                      fab
                      small
                    >
                      <span v-if="pipe.Serial_Number === Serialnr"
                        ><b>{{ pipe.Position }}</b></span
                      >
                      <span v-else class="black--text">{{
                        pipe.Position
                      }}</span>
                    </v-btn>
                  </template>
                  <h2 class="my-2">
                    <b>{{ pipe.Position }}</b>
                  </h2>
                  <div style="text-align: left">
                    <div><b>Serial Number:</b> {{ pipe.Serial_Number }}</div>
                    <div><b>Item Number:</b> {{ pipe.Item_No }}</div>
                    <div><b>Inspection Date:</b> {{ pipe.Date_Formatted }}</div>
                    <div><b>Description:</b> {{ pipe.Description }}</div>
                    <div><b>Current Location:</b> {{ currentLocation_without_underscore(pipe.Current_Location) }}</div>
                  </div>
                </v-tooltip>
              </td>
            </tr>
          </table>
        </v-row>
      </v-card>
    </v-dialog>
    <v-card
      v-if="$store.getters.getPipeType === 'Tubular'"
      dark
      color="transparent"
      width="99%"
      class="mx-auto mt-10"
      elevation="0"
    >
      <v-card-title class="text-h5">{{
        this.$route.meta.displayname
      }}</v-card-title>
      
      <v-container fluid class="d-flex justify-center"
      v-if="!get_data_load">
        <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          clearable
          :label="search_text"
          hide-details
        ></v-text-field>
        <v-spacer />
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="100"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
            >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs">mdi-pencil</v-icon>
              </template>
              <span>Edit Headers</span>
            </v-tooltip>
            </v-btn>
          </template>
        
          <v-data-table
          dense
          hide-default-footer
          :headers="editorHeader"
          :items="editorItems"
          v-sortable-data-table
          hide
          @sorted="saveOrder"
          item-key="text"
          show-select
          height="500"
          v-model="selectedHeader"
          disable-pagination
          >
            <template v-slot:top>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-btn text block :color="saveSettingsColor" @click="saveHeaders" :loading="get_data_load">save settings</v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6">
                  <v-btn text block color="error" @click="resetHeaders">reset headers</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn text block color="error" @click="resetFilters">reset filters</v-btn>
              </v-col>
              </v-row>
              
            </template>
            <template v-slot:item.text="{ item }">
              <span style="color:#1c355e">{{ item.text }}</span>
            </template>
          </v-data-table>
        </v-menu>

      
      <v-btn icon @click="invToExcel">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs">mdi-microsoft-excel</v-icon>
          </template>
          <span>Export Excel</span>
        </v-tooltip>
      </v-btn>
        
      <v-btn icon @click="invToPDF">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs">description</v-icon>
          </template>
          <span>Export PDF</span>
        </v-tooltip>
      </v-btn>
      </v-container>

      <v-container fluid class="d-flex justify-center" v-if="!get_data_load">
      <v-select clearable :items="filters['Site']" label="Select Site" v-model="site" prepend-icon="mdi-warehouse" />
      <v-spacer></v-spacer>
      </v-container>
      <v-data-table v-if="getUsername === 'Reelwell' && site === 'Reelwell'">

      </v-data-table>
      <v-data-table
        v-else
        :loading="get_data_load"
        :headers="selectedHeader"
        :items="filterItems"
        :search="search"
        id="inventoryTable"
        class="elevation-1 custom-table"
        :height="getTableHeight"
        fixed-header
        disable-pagination
        hide-default-footer
        dark
        :item-class="getRowClass"
      >

        <!-- TABLE HEADER -->
        <template v-for="(col, i) in filters" v-slot:[`header.${i}`]="{ header }">
          <v-menu :close-on-content-click="false" :nudge-width="200" offset-y transition="slide-y-transition" left fixed style="position: absolute"  v-if="RangeforOdfjell(i)">
              <!-- Filter button -->
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="indigo" icon v-bind="attrs" v-on="on">
                  <v-icon small 
                    :color="activeFilters[header.value] && activeFilters[header.value].length < filters[header.value].length ? 'red' : 'default'">
                    mdi-filter-variant
                  </v-icon>
                </v-btn>
              </template>

              <!-- Filter menu -->
              <v-list flat dense class="pa-0" max-height="500px" style="overflow-y: auto">
                <v-list-item-group multiple v-model="activeFilters[header.value]" class="py-2" v-if="header.value != 'Range'">
                  <template v-for="(item, i) in filters[header.value]">
                    <v-list-item :key="`${item}`" :value="item" :ripple="false">
                      <template v-slot:default="{ active, toggle }">
                        <v-list-item-action>
                          <v-checkbox :input-value="active" :true-value="item"
                            @click="toggle" color="1c355e" :ripple="false" dense></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content> 
                          <v-list-item-title v-text="item"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
                <!-- Custom value for Range -->
                <v-list-item-group v-else>
                  <v-list-item value="item" :ripple="false">
                    <template v-slot:default="{ active, toggle }">
                      <v-list-item-action>
                      <v-checkbox :input-value="active" value="true" v-model="range3_highlight"
                        @change="getRowClass" @click="toggle" color="1c355e" :ripple="false" dense></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-text="'Highlight Range 3'"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-list-item-group>

                <!-- Filter control buttons (clear and toggle all) -->
                <v-divider></v-divider>
                <v-row no-gutters>
                  <v-col cols="6">
                    <v-btn text block @click="toggleAll(header.value)" color="success">Toggle all</v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn text block @click="clearAll(header.value)" color="warning">Clear all</v-btn>
                  </v-col>
                </v-row>
              </v-list>
            </v-menu>

            <!-- Header text -->
            <span>{{ header.text }}</span>
        </template>

        <template v-for="(description, field) in {
          'ReqVTI': 'Number of joints from Rig Ready which requires VTI',
          'Rig_Ready': 'Number of joints that are ready for operation (Check for valid inspection date prior to ' +
                       'shipping)',
          // 'rigready_vti': 'Rig Ready: Number of joints that are ready for operation (Check for valid inspection ' +
          //                 'date prior to shipping) Req VTI: Number of joints from Rig Ready which requires VTI',
          'Inspection': 'Number of joints currently in the inspection process.',
          'Booked': 'Number of joints that are booked for shipping',
          'Backlog': 'Number of joints that are returned from offshore, and are awaiting inspection.',
          'Machining': 'Number of joints shipped for machining',
          'Hardbanding': 'Number of joints shipped for hardbanding repairs.',
          'Scrap': 'Number of joints that are scrapped.',
          'Limited_Service': 'Number of joints classified as limited service. This means that the condition of ' +
                             'the joints do not meet either the requirements in an inspection specification or ' +
                             'the requirements of the operator.',
          'Shipped': 'Number of joints that are shipped from Ocean IMR facilities, and not returned. This gives ' +
                     'an estimate of the number of joints that are currently in rotation that was originally ' +
                     'shipped from Ocean IMR.',
          'Total_Yard': 'Number of joints onshore either rig ready or lined up for inspection or repair.',
          'IPC': 'Number of joints rejected for IPC.',
          'On_Hold': 'Number of joints quarantined for operations.',
          'Total_Yard_with_Rejects': 'Number of joints at Ocean IMR\'s facilities, regardless of classification.'
        }" v-slot:[`header.${field}`]="{ header }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ header.text }}</span>
            </template>
            <span>{{ description }}</span>
          </v-tooltip>
        </template>
        
        <!-- Special case where I dont know how to add <br> -->
        <template v-slot:header.rigready_vti="{ header }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ header.text }}</span>
            </template>
            <span
              >Rig Ready: Number of joints that are ready for operation (Check for valid
              inspection date prior to shipping)
              <br>
              Req VTI: Number of joints from Rig Ready which requires VTI
              </span
            >
          </v-tooltip>
        </template>

        <template v-slot:item.action="{ item }">
          <v-btn small light @click="getPDF(item)"> PDF </v-btn>
        </template>

        <template v-slot:item.elevator_shank_od="{ item }">
          <div v-if="item.elevator_shank_od !== 'N/A' || item.elevator_shank_od === ' '">
            {{ item.elevator_shank_od }}
          </div>
          <div v-else>
            <span>N/A</span>
          </div>
        </template>
        <template v-slot:item.upset_type="{ item }">
          <div v-if="item.upset_type !== 'N/A'">
            {{ item.upset_type }}
          </div>
          <div v-else>
            <span>N/A</span>
          </div>
        </template>
        <template v-slot:item.KSI="{ item }">
          <div v-if="item.Equipment === 'Heavy Weight' && item.KSI !== ''">
            {{ item.KSI }}
          </div>
          <div v-else>
            <span>N/A</span>
          </div>
        </template>
        
        <template v-for="(method, field) in {
          'Rig_Ready': (item, location) => showPipes(item),
          'ReqVTI': (item, location) => showVTIPipes(item),
          'Booked': (item, location) => showBookedList(item),
          'Shipped': (item, location) => showShippedList(item),
          'Scrap': (item, location) => showOtherPipes(item, location),
          'Limited_Service': (item, location) => showOtherPipes(item, location),
          'Inspection': (item, location) => showOtherPipes(item, location),
          'Hardbanding': (item, location) => showOtherPipes(item, location),
          'On_Hold': (item, location) => showOtherPipes(item, location),
          'Machining': (item, location) => showOtherPipes(item, location)
          // showOtherPipes is the only method that uses location
        }" v-slot:[`item.${field}`]="{ item }">
          <v-tooltip left :disabled="item[field] == 0">
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="item[field] != 0"
                v-on="on"
                small
                text
                @click="method(item, field)"
              >{{ item[field] }}</v-btn>
              <v-btn v-else v-on="on" small text>{{ 0 }}</v-btn>
            </template>
            <span v-if="item[field] != 0">Show Pipes</span>
          </v-tooltip>
        </template>

        <!-- Special case where rigready_vti shows two values; Rig_Ready and ReqVTI -->
        <template v-slot:item.rigready_vti="{ item }">
          <v-tooltip left :disabled="item.Rig_Ready == 0">
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="item.Rig_Ready != 0"
                v-on="on"
                small
                text
                @click="showPipes(item)"
                >{{ item.Rig_Ready }}</v-btn
              >
              <v-btn v-else v-on="on" small text>{{ 0 }}</v-btn>
            </template>
            <span v-if="item.Rig_Ready != 0">Show Pipes</span>
          </v-tooltip>
          <v-tooltip right :disabled="item.ReqVTI == 0">
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="item.ReqVTI != 0"
                v-on="on"
                small
                text
                @click="showVTIPipes(item)"
                >({{ item.ReqVTI }})</v-btn
              >
              <v-btn v-else v-on="on" small text>({{ 0 }})</v-btn>
            </template>
            <span v-if="item.ReqVTI != 0">Show Pipes</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar v-model="snackbarError" color="red">
      {{ errorText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import image from "../assets/picture.png";
import moment from "moment";
import { json2excel, excel2json } from "js2excel";
import Papa from "papaparse";
import pipe_dialog from "@/components/inventory_pipe_dialog.vue";
import cert_menu from "@/components/cert_menu.vue";
import Sortable from 'sortablejs'
import inventoryBHA from '@/components/BHA Inventory Table.vue'
import data_table from '@/components/data_table.vue';
export default {
  name: "inventory",
  components: { pipe_dialog, cert_menu, inventoryBHA, data_table },
  data() {
    return {
      search_text: "Seach (Item number, Equipment type etc..)",
      location: "",
      pipe: {
        backgroundColor: "pink",
      },

      filters: { 'Site': [], 'Asset': [], 'Equipment': [], 'Connection': [], 'Tube_OD': [], 'TJ_OD': [], 'TJ_ID': [], 'Range': [] },
      activeFilters: {},

      header_min_height: 135,
      header_width: 500,
      header_C1: require("@/assets/site_img.jpg"),
      header_C2: require("@/assets/rack_img.jpg"),
      header_C3: require("@/assets/item_img.jpg"),
      colormap: "",
      rackinfo: "",
      site: '',
      pipeload: false,
      image: image,
      allpipes: [],
      Itemnr: null,
      Serialnr: null,
      n: null,
      numbers: [],
      dialog: false,
      dialog2: false,
      dialog3: false,
      certdialog: false,
      links: "",
      test: false,
      multiple_itemno: {},
      currentItem: null,
      range3_highlight: [],
      search: "",
      search2: "",
      editorHeader: [{ text: 'Select All', value: 'text', align: 'left', sortable: false }],
      editorItems: [],
      headers2: [
        { text: "DT No.", value: "DT_No" },
        { text: "Ref", value: "Ref" },
        { text: "Item No.", value: "Item_No" },
        { text: "Rig", value: "Rig" },
        { text: "Customer Rep.", value: "Customer_Name" },
        { text: "Quantity", value: "Quantity" },
        { text: "Shipped", value: "Shipped" },
        { text: "Offshore", value: "Offshore" },
        { text: "Returned", value: "Returned" },
        { text: "Shipped Date", value: "Date_Formatted" },
        { text: 'PDF', value: 'action', sortable: false }
      ],
      headers3: [
        {
          text: "Serial Number",
          align: "start",
          sortable: true,
          value: "Serial_Number",
        },
        { text: "Pipe Length", value: "KWP_Length" },
        { text: "Rack Position", value: "Rack_Position" },
        { text: "Position", value: "Position" },
        { text: "Current Location", value: "Current_Location" },
        { text: "Thread Compound Applied", value: "TC_TBA" },
        { text: "Inspection Date", value: "Date_Formatted" },
        { text: "DT No.", value: "DT_No" },
        { text: "Item No.", value: "Item_No" },
        { text: "Rig", value: "Rig" },
        { text: "Customer Rep.", value: "Customer_Name" },
        { text: "Shipped Date", value: "Shipped_Date" },
        { text: "Completion Date", value: "Completion_Date" },
      ],
      headers: [
        {
          text: "Item Number",
          align: "left",
          sortable: true,
          value: "Item_No", class: 'sticky-header'
        },
        { text: "Tube OD", value: "Tube_OD",
          filter: value => {
            return this.activeFilters.Tube_OD ? this.activeFilters.Tube_OD.includes(value) : true;
          } 
        },
        { text: "Connection", value: "Connection", align: "left",
          filter: value => {
            return this.activeFilters.Connection ? this.activeFilters.Connection.includes(value) : true;
          }
        },
        { text: "TJ OD", value: "TJ_OD" },
        { text: "TJ ID", value: "TJ_ID" },
        { text: "Weight", value: "Weight", align: "left" },
        { text: "Range", value: "Range", align: "center",
          filter: value => {
            return this.activeFilters.Range ? this.activeFilters.Range.includes(value) : true;
          } },
        { text: "Elevator Shank OD", value: "elevator_shank_od", align: "center" },
        { text: "Upset Type", value: "upset_type", align: "center" },
        { text: "KSI", value: "KSI", align: "center" },
        { text: "String Number", value: "String_Number", align: "center" },
        { text: "Part Number", value: "Part_No", align: "center" },
        { text: "Equipment", 
          value: "Equipment",
          align: "center",
          filter: value => {
            return this.activeFilters.Equipment ? this.activeFilters.Equipment.includes(value) : true;
          }
        },
        { text: "Site", 
          value: "Site",
          align: "center", 
          filter: value => {
            return this.activeFilters.Site ? this.activeFilters.Site.includes(value) : true;
          } 
        },
        { text: "Asset", 
          value: "Asset", class: 'sticky-header',
          filter: value => {
            if (this.activeFilters.Asset.length > 0) {
              return this.activeFilters.Asset ? this.activeFilters.Asset.includes(value) : true;
            } else {
              return true
            }
          }
        },
        { text: "Rig Ready", value: "Rig_Ready", align: ' d-none' },
        { text: "Req VTI", value: "ReqVTI", align: ' d-none' },
        { text: "Rig Ready (Req VTI)", value: "rigready_vti", align: "center" },
        { text: "Inspection", value: "Inspection", align: "center" },
        { text: "Booked", value: "Booked", align: "center" },
        { text: "Backlog", value: "Backlog", align: "center" },
        { text: "Machining", value: "Machining", align: "center" },
        { text: "Hardbanding", value: "Hardbanding", align: "center" },
        { text: "Scrap", value: "Scrap", align: "center" },
        { text: "Limited Service", value: "Limited_Service", align: "center" },
        { text: "IPC", value: "IPC", align: "center" },
        { text: "On Hold", value: "On_Hold", align: "center" },
        { text: "Shipped Scrap", value: "Shipped_Scrap", align: "center" },
        { text: "Shipped", value: "Shipped", align: "center" },
        { text: "Total Onshore incl. Rejects", value: "Total_Yard_with_Rejects", align: "center" },
        { text: "Total Onshore", value: "Total_Yard", align: "center" },
        { text: "Total Items", value: "Total_Item", align: "center" },
        { text: "Certificates", sortable: false, value: "action" },
      ],
      desserts: [],
      selectedHeader: [],
      originalHeader: [],
      userHeader: [],
      saveSettings: true,
      saveSettingsDialog: false,
      saveSettingsColor: 'success',
      nextRoute: null,
      headers_BHA: [
        { text: 'Serial Number', value: 'Serial_No' },
        { text: 'Material Number', value: 'Assets' },
        { text: 'Description', value: 'Description' },
        { text: 'Connection Box', value: 'Connection_Box' },
        { text: 'Connection Pin', value: 'Connection_Pin' },
        { text: 'Current Location', value: 'Current_Location' },
        { text: 'Last Inspection Date', value: 'Date' },
        { text: 'Days past due inspection', value: 'DateExpiration' },
        // { text: 'Equipment type', value: 'Equipment_Type' },
        // { text: 'Rack', value: 'Rack' },
        // { text: 'Rig', value: 'Rig' },
        // { text: 'Site', value: 'Site' },
      ],
      ODP_data: { Tubular: [], BHA: [] }
    };
  },
  beforeRouteLeave(to, from, next) {
    if (!this.saveSettings) {
      this.saveSettingsDialog = true
      this.nextRoute = next
    } else {
      next()
    }
  },
  mounted() {
    // TODO: get user saved headers from response
    const modifiedHeaders = this.headers.map(obj => {
    if (this.getUsername === "Rig Linus Contractor AS" && obj.value === "String_Number") {
        return { ...obj, text: "Asset Number" };
    }
    return obj;
    });
    this.headers = modifiedHeaders
    this.selectedHeader = this.headers
    this.originalHeader = this.headers.slice()
    this.$store.dispatch("set_data_load", true);
    if (this.$route.query.showrack !== undefined) {
      this.Serialnr = "";
      axios
        .get("/getPipes", {
          params: { rackname: this.$route.query.showrack },
        })
        .then((response) => {
          this.n = response.data.rackinfo.Maxrowcount;
          this.allpipes = this.$formatData(response.data.pipes, [
            "Date_Inspected",
          ]);
          this.rackinfo = response.data.rackinfo;
          this.colormap = response.data.colormap;
          this.multiple_itemno.number = response.data.number_of_items;
          this.multiple_itemno.istrue = response.data.number_of_items > 1;
          this.dialog2 = true;
          this.$store.dispatch("set_data_load", false);
        });
    } else {
      axios
        .get("/getInventory", {
          params: { username: this.$store.getters.getUsername, user_email: this.$store.getters.getEmail },
        })
        .then((response) => {
          if (response.data.headers.length === 0) {
            this.userHeader = this.headers
            this.selectedHeader = this.headers
            this.editorItems = this.headers.filter(obj => obj.value != 'Rig_Ready' && obj.value != 'ReqVTI')

          } else {
            let order = response.data.headers.map(a => a.value);
            var header_intersection = this.headers.filter(a => response.data.headers.some(b => a.value === b.value)).sort((a, b) => order.indexOf(a.value) - order.indexOf(b.value));
            
            this.userHeader = header_intersection
            this.selectedHeader = header_intersection
            this.editorItems = this.headers.filter(obj => obj.value != 'Rig_Ready' && obj.value != 'ReqVTI').sort((a, b) => order.indexOf(a.value) - order.indexOf(b.value));
          }

          this.changedHeaders(false)
          this.desserts = response.data.data.filter(item => {
            return !this.getExcludedAssets.includes(item.Asset);
          });

          // Keeps last occurence of Item No, i.e., Mongstad and KWP sites are excluded
          // let result = this.desserts.filter((value, index, self) =>
          //   index === self.findIndex((t) => (
          //     t.Item_No === value.Item_No
          //   )))

          // this.desserts = result
          
          this.initFilters()

          // Set color code for Range 3 in inventory for Julius Jocius - Odfjell Technology
          if (this.getEmail === "jjoc@odfjelltechnology.com") {
            this.range3_highlight = ["true"]
          }

          this.$store.dispatch("set_data_load", false);
        });

      // Fetch BHA data
      this.$initialDataLoad(this.$route.meta.backendRoutes, { username: this.$store.getters.getUsername }, "Date")
    }
    var test = document.getElementById("inventoryTable").childNodes
  },
  directives: {
    sortableDataTable: {
      bind (el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function (event) {
            vnode.child.$emit('sorted', event)
          }
        }
        Sortable.create(el.getElementsByTagName('tbody')[0], options)
      }
    }
  },
  watch: {
    site() {
      var filteredSite = this.filters['Site'].filter(name => name.includes(this.site))
      if (filteredSite.length === 0) {
        this.activeFilters['Site'] = this.filters['Site']
      } else {
        this.activeFilters['Site'] = filteredSite
      }
    },
    desserts (val) {
      this.initFilters()
      this.site = 'Kverneland'
    },
    headers() {
      var tempSelected = this.selectedHeader
      var orderedHeader = []
      this.selectedHeader = this.headers
      for (var i = 0; i < this.selectedHeader.length; i++) {
        if (tempSelected.includes(this.selectedHeader[i])) {
          orderedHeader.push(this.selectedHeader[i])
        }
      }
      this.selectedHeader = orderedHeader
    },
    editorItems() {
      var tempSelected = this.selectedHeader
      var orderedHeader = []
      this.selectedHeader = [...this.editorItems]
      for (var i = 0; i < this.selectedHeader.length; i++) {
        if (tempSelected.includes(this.selectedHeader[i])) {
          orderedHeader.push(this.selectedHeader[i])
        }
      }
      this.selectedHeader = orderedHeader
    },
    selectedHeader() {
      const objectsEqual = (o1, o2) =>
      Object.keys(o1).length === Object.keys(o2).length 
          && Object.keys(o1).every(p => o1[p] === o2[p]);

      if (objectsEqual(this.selectedHeader, this.userHeader)) {
        this.changedHeaders(false)
      } else {
        this.changedHeaders(true)
      }

    }
  },
  methods: {
    ...mapActions(["set_error_state"]),
    // Method to hide Range 3 filter to other customers than Odfjell
    RangeforOdfjell(col) {
      if (this.getUsername != 'Odfjell Technology' && col === 'Range') {
        return false
      } else {
        return true
      }
      
    },
    getRowClass(item) {
      if (item.Range === "3" && this.range3_highlight.length > 0) {
        console.log('true');
        return 'highlight-row' 
      } return ''
  },
    isDuplicate(item, arr) { 
      return arr.some(el => item.Item_No === el.Item_No && item.Site === el.Site);
    },
    changedHeaders(value) {
      if (value) {
        this.saveSettings = false
        this.saveSettingsColor = 'warning'
      } else {
        this.saveSettings = true
        this.saveSettingsColor = 'success'
      }
    },
    saveHeaders() {
      this.$store.dispatch("set_data_load", true);
      axios.post("/setUserInventoryHeader", {
        user_email: this.$store.getters.getEmail,
        header: this.selectedHeader
      }).then(() => {
        this.saveSettings = true
        this.saveSettingsColor = 'success'
        this.$store.dispatch("set_data_load", false);
        // this.nextRoute()
      })
      
    },
    resetHeaders() {
      this.headers = this.originalHeader.slice(0)
      this.selectedHeader = this.originalHeader.slice(0)
      this.editorItems = [...this.headers].filter(obj => obj.value != 'Rig_Ready' && obj.value != 'ReqVTI')
    },
    resetFilters() {
      this.activeFilters = Object.assign({}, this.filters); 
    },
    saveOrder (event) {
      const movedItem = this.editorItems.splice(event.oldIndex, 1)[0];
      this.editorItems.splice(event.newIndex, 0, movedItem);
    },
    initFilters() {
      for (var col in this.filters) {
        this.filters[col] = this.desserts.map((d) => { return d[col] }).filter(
          (value, index, self) => { if (value !== '') return self.indexOf(value) === index }
        )
      }
      this.activeFilters = Object.assign({}, this.filters)
    },
    toggleAll (col) {
      if (col === "Site") {
        this.site = ''
      }
      this.activeFilters[col] = this.desserts.map((d) => { return d[col] }).filter(
        (value, index, self) => { if (value !== '') return self.indexOf(value) === index }
      )
    },
    clearAll (col) {
      if (col === "Site") {
        this.site = ''
      }
      this.activeFilters[col] = []
    },

    addBorder(value) {
      const color = this.computeColor(value);
      const elements = this.$refs.box;

      for (var i = 0; i < elements.length; i++) {
        if (color === elements[i].bgColor) {
          elements[i].childNodes[0].setAttribute(
            "style",
            "border:2px solid black !important"
          );
        } else {
          elements[i].childNodes[0].setAttribute("style", "border: ");
        }
      }
    },
    removeBorder() {
      const elements = this.$refs.box;

      for (var i = 0; i < elements.length; i++) {
        elements[i].childNodes[0].setAttribute("style", "border: ");
      }
    },
    computeColor(pipe) {
      if (Object.keys(this.colormap).length !== 0 && Object.keys(this.colormap)[0].trim().length !== 0) {
        return this.colormap[pipe].color;
      } else {
        return "white";
      }
    },
    getPDF(links) {
      this.links = links;
      this.certdialog = true;
    },
    showRack(pipedata) {
      this.$store.dispatch("set_data_load", true);
      this.Serialnr = pipedata.Serial_Number;
      console.log("Show Rack: ", pipedata.Rack_Position)
      if (pipedata.Rack_Position === "Scrap OCTG") {
        var params = { rackname: "ScrapOCTG" }
      } else {
        var params = { rackname: pipedata.Rack_Position }
      }
      axios
        .get("/getPipes", {
            params: params,
        })
        .then((response) => {
          this.n = response.data.rackinfo.Maxrowcount;
          this.allpipes = this.$formatData(response.data.pipes, [
            "Date_Inspected",
          ]);
          this.rackinfo = response.data.rackinfo;
          this.colormap = response.data.colormap;
          this.multiple_itemno.number = response.data.number_of_items;
          this.multiple_itemno.istrue = response.data.number_of_items > 1;
          this.dialog2 = true;
          this.$store.dispatch("set_data_load", false);
        });
    },
    clearList() {
      this.numbers = [];
      this.dialog = false;
      this.dialog3 = false;
      this.Itemnr = null;
    },
    JSONToCSVConvertor (JSONData, ReportTitle, ShowLabel) {
    // If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    // Filter out JSONData according to this.search (search bar).
    // console.log(JSONData)
      /* JSONData = JSONData.filter(row => {
        console.log(row)
        return row['Item_No'].toLower$Case().indexOf(this.search.toLowerCase()) === 0
      }) */
      //JSONData = JSONData.slice(0,4)

      
        
      let selected = this.selectedHeader.map(function(value) {
        return value.value;
      });

      if (selected.includes("rigready_vti")) {
        selected.push("Rig_Ready")
        selected.push("ReqVTI")
      }
      
      let unwanted = Object.keys(JSONData[0]).filter(x => !selected.includes(x));
      
      for (let i = 0;i < JSONData.length;i++) {
        unwanted.forEach(function(key) {
        delete JSONData[i][key]
        });
      }
         
      var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData

      var CSV = 'sep=,' + '\r\n\n'

      // This condition will generate the Label/Header
      if (ShowLabel) {
        var row = ''

        // This loop will extract the label from 1st index of on array
        for (var index in arrData[0]) {
          // Now convert each value to string and comma-seprated
          row += index + ','
        }

        row = row.slice(0, -1)

        // append Label row with line break
        CSV += row + '\r\n'
      }

      // 1st loop is to extract each row
      for (var i = 0; i < arrData.length; i++) {
        var row = ''

        // 2nd loop will extract each column and convert it in string comma-seprated
        for (var index in arrData[i]) {
          // console.log(String(arrData[i][index]).replace(",","."))
          row += '="' + String(arrData[i][index]).replace(',', '.').replace('"', '') + '",'
        }
        // console.log(row)
        row.slice(0, row.length - 1)

        // add a line break after each row
        CSV += row + '\r\n'
      }

      if (CSV == '') {
        alert('Invalid data')
        return
      }

      // Generate a file name
      var fileName = ReportTitle
      // //this will remove the blank-spaces from the title and replace it with an underscore
      // fileName += ReportTitle.replace(/ /g,"_");

      // Initialize file format you want csv or xls
      var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)

      // Now the little tricky part.
      // you can use either>> window.open(uri);
      // but this will not work in some browsers
      // or you will not get the correct file extension

      // this trick will generate a temp <a /> tag
      var link = document.createElement('a')
      link.href = uri

      // set the visibility hidden so it will not effect on your web-layout
      link.style = 'visibility:hidden'
      link.download = fileName + '.csv'

      // this part will append the anchor tag and remove it after automatic click
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    invToPDF() {
      this.$store.dispatch("set_data_load", true);
      axios
        .get("/getInventoryPDF", {
          params: { username: this.getUsername, asset: this.getAsset } 
        })
        .then(response => {
          window.open(response.data.pdflink, "_blank");
          this.$store.dispatch("set_data_load", false);
        });
    },
    clearRack() {
      this.dialog2 = false;
      this.n = null;
      this.allpipes = [];
      this.rackinfo = "";
    },
    invToExcel() {
      this.JSONToCSVConvertor(this.filterItems, 'InventoryTally', true)
    },
    showPipes(item) {
      if (item.Rig_Ready > 0) {
        this.$store.dispatch("set_data_load", true);
        axios
          .get("showPipelist", {
            params: { itemnr: item.Item_No },
          })
          .then((response) => {
            this.numbers = this.$formatData(response.data, ["Date_Inspected"]);
            this.$store.dispatch("set_data_load", false);
            this.dialog3 = true;
            this.Itemnr = item.Item_No;
            this.location = "Rig Ready";
          });
      }
    },
    showOCTG(item) {
      if (item.Rig_Ready > 0) {
        this.$store.dispatch("set_data_load", true);
        var params = new URLSearchParams();
        params.append("rackname", item.Item_No);
        params.append("isOCTG", true);
        var request = { params: params };
        axios.get("getPipes", request).then((response) => {
          this.numbers = this.$formatData(response.data, ["Date_Inspected"]);
          this.$store.dispatch("set_data_load", false);
          this.dialog3 = true;
          this.Itemnr = item.Item_No;
          this.location = "Rig Ready";
        });
      }
    },
    getDateDiff: function (pipe) {
      var now = +new Date() / 1000;
      var end = parseInt(pipe.Date_Inspected);
      var diff = Math.floor((now - end) / 60 / 60 / 24);
      return diff;
    },
    showOtherPipes(item, location) {
      this.$store.dispatch("set_data_load", true);
      axios
        .get("/showOtherlist", {
          params: { itemnr: item.Item_No, location: location },
        })
        .then((response) => {
          this.numbers = this.$formatData(response.data, ["Date_Inspected"]);
          this.$store.dispatch("set_data_load", false);
          this.dialog3 = true;
          this.location = location;
          this.Itemnr = item.Item_No;
        });
    },
    showVTIPipes(item) {
      this.$store.dispatch("set_data_load", true);
      axios
        .get("/showVTIlist", {
          params: { itemnr: item.Item_No},
        })
        .then((response) => {
          this.numbers = this.$formatData(response.data, ["Date_Inspected"]);
          this.$store.dispatch("set_data_load", false);
          this.dialog3 = true;
          this.location = "VTI";
          this.Itemnr = item.Item_No;
        });
    },
    showBookedList(item) {
      this.$store.dispatch("set_data_load", true);
      axios
        .get("/showBookedList", {
          params: { itemnr: item.Item_No },
        })
        .then((response) => {
          this.numbers = this.$formatData(response.data, ["DT_Date"]);
          this.$store.dispatch("set_data_load", false);
          this.dialog = true;
          this.location = "Booked DT";
          this.Itemnr = item.Item_No;
        });
    },
    showShippedList(item) {
      this.$store.dispatch("set_data_load", true);
      axios
        .get("/showShippedList", {
          params: { itemnr: item.Item_No },
        })
        .then((response) => {
          this.numbers = this.$formatData(response.data, ["DT_Date"]);
          this.$store.dispatch("set_data_load", false);
          this.dialog = true;
          this.location = "Shipped DT";
          this.Itemnr = item.Item_No
        });
    },
    showShippedSerials(itemnr) {
      console.log(itemnr);
      this.$store.dispatch("set_data_load", true);
      axios
        .get("/showShippedSerials", {
          params: { itemnr: itemnr },
        })
        .then((response) => {
          this.numbers = this.$formatData(response.data, ["Date_Inspected"]);
          this.$store.dispatch("set_data_load", false);
          this.dialog3 = true;
          this.location = 'Shipped';
          this.Itemnr = itemnr;
          this.currentItem = this.numbers
        });
    },
    showShippedDT(item) {
      
      this.$store.dispatch("set_data_load", true);
      axios
        .get("/showShippedDT", {
          params: { dt_no: item.DT_No },
        })
        .then((response) => {
          this.numbers = this.$formatData(response.data, ["Date_Inspected"]);
          this.$store.dispatch("set_data_load", false);
          this.dialog3 = true;
          this.location = 'Shipped';
          this.Itemnr = item.Item_No;
          this.currentItem = item
        });
    },
    inventoryToExcel() {
      let config = {
        quotes: false, //or array of booleans
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ",",
        header: true,
        newline: "\r\n",
        skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
        columns: [
          "Item_No",
          "Tube_OD",
          "Range",
          "Equipment",
          "Site",
          "Asset",
          "Rig_Ready",
          "Inspection",
          "Booked",
          "Backlog",
          "Wepco",
          "Stamas",
          "Norse",
          "Hardbanding",
          "Scrap",
          "Limited_Service",
          "On_Hold",
          "Shipped",
          "Total_Yard",
        ],
      };
      let inv = this.desserts;
      inv.forEach(function (item) {
        delete item.PDF;
      });
      var blob = new Blob([Papa.unparse(inv, config)], {
        type: "text/csv;charset=utf-8,",
      });
      var link = document.createElement("a");

      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "ODP_Inventory.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    currentLocation_without_underscore(current_location) {
      return current_location.replace(/_/g, ' ')
    },
  },
  computed: {
    ...mapGetters(["getUsername", "getAsset", "getFleet", "get_data_load", "getExcludedAssets", "getEmail"]),
    getTableHeight() {
      return window.innerHeight - 270
    },
    divideRows() {
      const chunkarray = [];
      const fullarray = this.allpipes;
      let i;
      let j;
      let temparray;
      const chunk = this.n;
      // Change for several maxrowcounts
      for (i = 0, j = fullarray.length; i < j; i += chunk) {
        temparray = fullarray.slice(i, i + chunk);
        chunkarray.push(temparray);
      }
      return chunkarray.reverse();
    },
    filterItems() {
      if (this.getFleet === true) {
        return this.desserts;
      } else {
        return this.desserts.filter((item) => {
          return item.Asset === this.getAsset;
        });
      }
    },
    // editorItems() {
    //   console.log("changed");
    //   return this.headers.filter(obj => obj.value != 'Rig_Ready' && obj.value != 'ReqVTI')
    // },
    check_itemnr() {
      return this.colormap.length > 1;
    },
  },
};
</script>
<style scoped>

::v-deep .highlight-row {
  background-color: #3D7CC9; /* or any color you prefer */
}

::v-deep .tooltippen {
  text-align: left;
}

::v-deep table {
  margin: auto;
}
::v-deep .tdclass {
  border: 1px solid black;
  padding: 1px;
}

::v-deep .v-data-table-header th {
  white-space: nowrap;
}


::v-deep .theme--dark.v-data-table {
  background-color: #1c355e;
}

.v-input.v-text-field.v-select {
  max-width: 300px;
}

::v-deep .v-input__slot::before {
  border-style: none !important;
}

.v-menu__content {
  text-align: start !important;
}

::v-deep .theme--dark.v-data-table-header {
  background-color: rgba(255, 255, 255, 0.8);
}

::v-deep
  .theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  color: #1c355e;
}

::v-deep .theme--dark.v-data-table.v-data-table--fixed-header thead th{
  background-color: #ffffff !important;
}

::v-deep .custom-table thead th {
  background-color: rgba(255, 255, 255, 0.9);
}

::v-deep .custom-table thead th:first-child {
  border-radius: 10px 0 0 0;
}

::v-deep .custom-table thead th:last-child {
  border-radius: 0 10px 0 0;
}

::v-deep tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

::v-deep
  .theme--dark.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: #1c355e !important;
}

::v-deep .v-dialog--fullscreen {
  overflow-x: hidden;
}

.header-container {
  margin: auto;
  width: 500px;
  height: 135px;
  position: relative;
  color: white;
  overflow: hidden;
}

.header-title {
  bottom: 0;
  margin: 0px 0px 10px 10px;
  position: absolute;
  text-align: left;
  z-index: 3;
}

.header-subtitle {
  top: 0;
  margin: 50px 0px 0px 10px;
  position: absolute;
  text-align: left;
  z-index: 3;
}

.header-img {
  z-index: 1;
  filter: blur(4px);
}

::v-deep .v-data-table__wrapper::-webkit-scrollbar {
    width: 1px;
    /* height: 8px; */
    background-color: red;
}

/* Track */
::v-deep .v-data-table__wrapper::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
</style>
